<template>
  <div>
    <!-- modal -->
    <b-modal
      id="modal-primary-user-info"
      :ok-only="false"
      ok-title="ปิด"
      ok-only
      modal-class="modal-primary-user-info"
      :title="_title"
      size="lg"
      @ok="clickSave"
      @cancel="clickCancel"
    >

      <b-row v-if="userInfo">
        <b-col cols="12">
          <b-form-group
            label="ชื่อ"
            label-for="a-first_name"
          >
            <validation-provider
              #default="{ errors }"
              name="first_name"
              rules="required"
            >
              <b-form-input
                id="a-first_name"
                v-model="userInfo.user_info.name"
                :state="errors.length > 0 ? false : null"
                type="text"
                disabled
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="เครดิตคงเหลือ"
            label-for="a-credit_sandbox"
          >
            <validation-provider
              #default="{ errors }"
              name="credit_sandbox"
              rules="required"
            >
              <b-form-input
                id="a-credit_sandbox"
                v-model="userInfo.user_info.credit_sandbox"
                :state="errors.length > 0 ? false : null"
                type="text"
                disabled
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="รายได้"
            label-for="a-credit_balance"
          >
            <validation-provider
              #default="{ errors }"
              name="credit_balance"
              rules="required"
            >
              <b-form-input
                id="a-credit_balance"
                v-model="userInfo.user_info.credit_balance"
                :state="errors.length > 0 ? false : null"
                type="text"
                disabled
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="E-mail"
            label-for="a-Email"
          >
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required"
            >
              <b-form-input
                id="a-Email"
                v-model="userInfo.user_info.email"
                :state="errors.length > 0 ? false : null"
                type="text"
                disabled
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="ช่องทางการสมัคร"
            label-for="a-provider"
          >
            <validation-provider
              #default="{ errors }"
              name="provider"
              rules="required"
            >
              <b-form-input
                id="a-Email"
                v-model="userInfo.user_info.provider"
                :state="errors.length > 0 ? false : null"
                type="text"
                disabled
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Device Token"
            label-for="a-provider"
          >
            <validation-provider
              #default="{ errors }"
              name="provider"
              rules="required"
            >
              <b-form-input
                id="a-device-token"
                v-model="userInfo.user_info.device_token"
                :state="errors.length > 0 ? false : null"
                type="text"
                disabled
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { BButton, BModal, VBModal } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, numeric, double, email } from '@validations'
export default {
  data() {
    return {
      userInfo: '',
    }
  },
  computed: {
    _title() {
      return `ข้อมูลผุ้ใช้งานระบบ`
    },
  },
  components: {
    BButton,
    BModal,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  methods: {
    async show(data) {
      this.userInfo = ''
      this.userInfo = await this.api.get(`/api/admin/users/${data.id}`)
      this.$bvModal.show('modal-primary-user-info')
    },
    async clickSave() {
      this.$bvModal.hide('modal-primary-user-info')
      this.$emit('reload', '')
    },
    clickCancel() {},
  },
}
</script>
