var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-primary-user-info","ok-only":false,"ok-title":"ปิด","ok-only":"","modal-class":"modal-primary-user-info","title":_vm._title,"size":"lg"},on:{"ok":_vm.clickSave,"cancel":_vm.clickCancel}},[(_vm.userInfo)?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"ชื่อ","label-for":"a-first_name"}},[_c('validation-provider',{attrs:{"name":"first_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"a-first_name","state":errors.length > 0 ? false : null,"type":"text","disabled":""},model:{value:(_vm.userInfo.user_info.name),callback:function ($$v) {_vm.$set(_vm.userInfo.user_info, "name", $$v)},expression:"userInfo.user_info.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3359567678)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"เครดิตคงเหลือ","label-for":"a-credit_sandbox"}},[_c('validation-provider',{attrs:{"name":"credit_sandbox","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"a-credit_sandbox","state":errors.length > 0 ? false : null,"type":"text","disabled":""},model:{value:(_vm.userInfo.user_info.credit_sandbox),callback:function ($$v) {_vm.$set(_vm.userInfo.user_info, "credit_sandbox", $$v)},expression:"userInfo.user_info.credit_sandbox"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,629808155)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"รายได้","label-for":"a-credit_balance"}},[_c('validation-provider',{attrs:{"name":"credit_balance","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"a-credit_balance","state":errors.length > 0 ? false : null,"type":"text","disabled":""},model:{value:(_vm.userInfo.user_info.credit_balance),callback:function ($$v) {_vm.$set(_vm.userInfo.user_info, "credit_balance", $$v)},expression:"userInfo.user_info.credit_balance"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,20932667)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"E-mail","label-for":"a-Email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"a-Email","state":errors.length > 0 ? false : null,"type":"text","disabled":""},model:{value:(_vm.userInfo.user_info.email),callback:function ($$v) {_vm.$set(_vm.userInfo.user_info, "email", $$v)},expression:"userInfo.user_info.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2855489563)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"ช่องทางการสมัคร","label-for":"a-provider"}},[_c('validation-provider',{attrs:{"name":"provider","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"a-Email","state":errors.length > 0 ? false : null,"type":"text","disabled":""},model:{value:(_vm.userInfo.user_info.provider),callback:function ($$v) {_vm.$set(_vm.userInfo.user_info, "provider", $$v)},expression:"userInfo.user_info.provider"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3921932086)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Device Token","label-for":"a-provider"}},[_c('validation-provider',{attrs:{"name":"provider","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"a-device-token","state":errors.length > 0 ? false : null,"type":"text","disabled":""},model:{value:(_vm.userInfo.user_info.device_token),callback:function ($$v) {_vm.$set(_vm.userInfo.user_info, "device_token", $$v)},expression:"userInfo.user_info.device_token"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3400960841)})],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }